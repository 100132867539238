import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import CardGroup from "react-bootstrap/CardGroup"
import Carousel from "react-bootstrap/Carousel"

import { TiStar } from "react-icons/ti"

import Layout from "../components/layout"
import SEO from "../components/SEOadvanced"

import Features from "../components/Features"
import Callback from "../components/Callback"
import Onmap from "../components/Onmap"
// import TestimonialsBlock from "../components/Testimonials"

const IndexPage = ({ data: { categories, homeGallery, testimonials } }) => (
  <>
    <SEO
      title="👉Б'юті-майстер у Білогородці"
      description="Професійні послуги 💅🏻манікюру під кутикулу, педикюру і 👧депіляції в Білогородці.
      Прийом за попереднім записом в салоні Le Beaute Studio в ЖК Білий Шоколад."
    />
    <Layout backgroundClass="firstscreen home">
      <section className="categories py-5">
        <h2 className="mb-5 text-center uppercase">Послуги</h2>
        <Container className="mb-5">
          <Row>
            <CardGroup className="w-100">
              {categories.nodes.map(category => (
                <Card border="light" key={category.id} className="w-100">
                  <Img
                    className="card-img-top category-image"
                    fluid={category.categoryImage.fluid}
                  />
                  <Card.Body>
                    <Card.Title className="uppercase">
                      {category.categoryName}
                    </Card.Title>
                    <Card.Text className="card-tex-minheight">
                      {category.serviceHeroText.serviceHeroText}
                    </Card.Text>
                    <Link
                      to={`/` + category.categorySlug + `/`}
                      className="btn btn-danger btn-block uppercase"
                    >
                      Ціни
                    </Link>
                  </Card.Body>
                </Card>
              ))}
            </CardGroup>
          </Row>
        </Container>
      </section>
      <Features />
      <section className="works-galery pt-5">
        <Container className="my-5">
          <h2 className="mb-5 text-center uppercase">Мої роботи</h2>
          <Row>
            {homeGallery.images
              .reverse()
              .slice(0, 12)
              .map(image => (
                <Col
                  key={image.id}
                  lg={3}
                  md={4}
                  className="mb-4 h-100 d-block"
                >
                  <Img
                    fluid={image.fluid}
                    alt={image.title}
                    className="img-fluid"
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </section>
      <section className="carousel pt-5">
        <Container fluid={false} className="my-5">
          <h2 className="mb-5 text-center uppercase">Відгуки</h2>
          <Row>
            <Carousel
              indicators={false}
              controls={true}
              touch={true}
              fade={false}
            >
              {testimonials.nodes.map(testimonial => (
                <Carousel.Item key={testimonial.id}>
                  <Col lg={{ span: 8, offset: 2 }} md={12} sm={12}>
                    <div className="testimonial-author text-center mb-2">
                      {testimonial.testimonialAuthorName}
                    </div>
                    <div className="star-rating d-flex justify-content-center mb-4">
                      <TiStar />
                      <TiStar />
                      <TiStar />
                      <TiStar />
                      <TiStar />
                    </div>
                    <p className="text-center">
                      {/* {testimonial.testimonialStars} */}
                      {
                        testimonial.testimonialText.json.content[0].content[0]
                          .value
                      }
                    </p>
                    <Img
                      fluid={testimonial.testimonialScreen.fluid}
                      title={
                        `скріншот відгуку від ` +
                        testimonial.testimonialAuthorName
                      }
                      alt={`відгуку від ` + testimonial.testimonialAuthorName}
                      className="img-fluid"
                    />
                  </Col>
                </Carousel.Item>
              ))}
            </Carousel>
          </Row>
        </Container>
      </section>
      {/* <TestimonialsBlock /> */}
      <Onmap />
      <Callback />
    </Layout>
  </>
)

export default IndexPage

export const query = graphql`
  {
    services: allContentfulServices {
      nodes {
        serviceName
        serviceDurationInMinutes
        servicePrice
      }
    }
    categories: allContentfulServiceCategories {
      nodes {
        id
        categoryName
        categorySlug
        serviceHeroText {
          serviceHeroText
        }
        categoryImage {
          fluid(maxWidth: 800, maxHeight: 800) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    homeGallery: contentfulGallery(galeryName: { eq: "Homepage Gallery" }) {
      images {
        id
        title
        fluid(maxWidth: 800, maxHeight: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    bgimage: contentfulAsset(
      id: { eq: "93084b4b-7f4e-5a69-a2bf-f6034cc478fb" }
    ) {
      id
      title
      fluid(maxWidth: 1200, maxHeight: 800) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    testimonials: allContentfulTestimonials {
      nodes {
        id
        testimonialAuthorName
        testimonialStars
        testimonialUrl
        testimonialScreen {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        testimonialText {
          json
        }
      }
    }
  }
`
